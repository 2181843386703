import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomScheduleLink } from './custom-schedule.model';
import {
  ConfigurableScheduleData,
  ScheduleFlow,
} from '../../common/models/schedule-data.model';
import { HttpClient } from '@angular/common/http';
import { ScheduleLinkService } from '../../schedule-link/schedule-link.service';

@Injectable({
  providedIn: 'root',
})
export class CustomScheduleService {
  private readonly customLinkHeaderName$: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly scheduleLinkService: ScheduleLinkService
  ) {}

  mapCustomScheduleData(
    customData: CustomScheduleLink,
    from_widget?: string,
    mode?: string
  ): ConfigurableScheduleData {
    const { practices, serviceTypes, providers } =
      this.scheduleLinkService.mapScheduleLinkData(customData);
    this.setCustomLinkHeaderName(customData);

    return {
      flow: ScheduleFlow.CUSTOM,
      practiceGroupName: customData.dental_group_name,
      mainPractice: practices[0] || null,
      practices: practices || [],
      serviceTypes,
      providers,
      patient: null,
      perfectSlot: null,
      proposedTimeSlot: null,
      scheduleTimeFrame: null,
      configuration: {
        linkId: customData.custom_schedule_link_id,
        from_widget,
        mode,
        requestInsuranceInfo: customData.request_insurance_information,
      },
    };
  }

  decodeCustomScheduleLink(
    uniqueHash: string,
    linkName: string,
    from_widget?: string,
    mode?: string
  ): Observable<CustomScheduleLink> {
    let url = `custom-schedule-links/${uniqueHash}/${linkName}`;
    const queryParams = [];

    if (from_widget) {
      queryParams.push(`from_widget=${from_widget}`);
    }

    if (mode) {
      queryParams.push(`mode=${mode}`);
    }

    if (queryParams.length > 0) {
      url = `${url}?${queryParams.join('&')}`;
    }

    return this.http.get<CustomScheduleLink>(url);
  }

  setCustomLinkHeaderName(customScheduleLink: CustomScheduleLink): void {
    this.customLinkHeaderName$.next(customScheduleLink.header_name);
  }

  getCustomLinkHeaderName(): Observable<string> {
    return this.customLinkHeaderName$.asObservable();
  }
}
