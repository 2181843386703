import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  AvailableScheduleStep,
  ConfigurableScheduleStep,
  ScheduleStep,
  ScheduleStepId,
  ScheduleStepName,
  StepState,
} from './schedule-step.model';
import { Practice } from '../../common/practice';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ScheduleStepsService {
  @Output() locations = new EventEmitter<Practice[]>();
  @Output() selectedLocations = new EventEmitter<number>();
  @Output() selectMarker = new EventEmitter<number>();
  @Output() isLoaded = new EventEmitter<boolean>();

  readonly allStepsValid$: BehaviorSubject<string> =
    new BehaviorSubject<string>('not-valid');

  readonly stepsLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public currentStepId = 1;
  public currentStepIdx = 0;
  public isPracticeValid = false;
  public fromMenu = false;

  readonly steps$: BehaviorSubject<ScheduleStep[]> = new BehaviorSubject<
    ScheduleStep[]
  >([]);

  private readonly availableSteps: AvailableScheduleStep[] = [
    {
      slug: ScheduleStepName.PATIENT_DETAILS,
      label: 'Patient details',
    },
    {
      slug: ScheduleStepName.FAMILY,
      label: 'Family',
    },
    {
      slug: ScheduleStepName.REASON_FOR_VISIT,
      label: 'Reason for visit',
    },
    {
      slug: ScheduleStepName.TIME_SLOT,
      label: 'Time slot',
    },
    {
      slug: ScheduleStepName.SUMMARY,
      label: 'Summary',
    },
  ];

  private readonly lastPage: string = 'success-page';

  constructor(private readonly router: Router) {}

  get steps(): ScheduleStep[] {
    return this.steps$.getValue();
  }

  get firstStep(): ScheduleStep {
    return this.steps[0];
  }

  get lastStep(): ScheduleStep {
    return this.steps[this.steps.length - 1];
  }

  get firstAvailableStep(): ScheduleStep {
    return this.steps.find(({ disabled }) => !disabled);
  }

  buildScheduleSteps(steps: ConfigurableScheduleStep[]): ScheduleStep[] {
    return steps.map((step, idx) => {
      const newStep = this.availableSteps.find(
        ({ slug }) => slug === step.slug
      );

      return {
        id: idx + 1,
        label: newStep.label,
        command: newStep.slug,
        passed: step.passed ?? false,
        active: false,
        selectable: true,
        disabled: !!step.disabled,
        hint: step.hint || null,
      };
    });
  }

  setSteps(steps: ScheduleStep[]): void {
    this.steps$.next(steps);
  }

  getSteps(): ScheduleStep[] {
    return this.steps;
  }

  getStepBySlug(slug: string): ScheduleStep {
    return this.steps.find(({ command }) => command === slug);
  }

  isFirstStep(): boolean {
    return this.currentStepIdx === 0;
  }

  isLastStep(): boolean {
    return this.currentStepIdx === this.steps.length - 1;
  }

  canGoBack(): boolean {
    return !this.isFirstStep || !this.steps[this.currentStepIdx - 1]?.disabled;
  }

  setNextStep(): StepState {
    if (this.currentStepIdx + 1 > this.steps.length) {
      return null;
    }

    if (this.currentStepIdx + 1 === this.steps.length) {
      return {
        isFirst: false,
        isLast: true,
        command: this.lastPage,
      };
    }

    const prevStep = {
      ...this.steps[this.currentStepIdx],
      active: false,
      passed: true,
    };

    const currStep = {
      ...this.steps[this.currentStepIdx + 1],
      active: true,
    };

    const newSteps = [
      ...this.steps.slice(0, this.currentStepIdx),
      prevStep,
      currStep,
      ...this.steps.slice(this.currentStepIdx + 2),
    ];

    this.setSteps(newSteps);

    this.currentStepIdx += 1;
    this.currentStepId += 1;

    this.allStepsValid$.next(this.checkAllStepsValid());

    return {
      isFirst: false,
      isLast: false,
      command: currStep.command,
    };
  }

  setPreviousStep(): StepState {
    if (this.currentStepIdx === 0) {
      return null;
    }

    const prevStep = {
      ...this.steps[this.currentStepIdx],
      active: false,
      passed: false,
    };

    const currStep = {
      ...this.steps[this.currentStepIdx - 1],
      active: true,
    };

    const newSteps = [
      ...this.steps.slice(0, this.currentStepIdx - 1),
      currStep,
      prevStep,
      ...this.steps.slice(this.currentStepIdx + 1),
    ];

    this.setSteps(newSteps);

    this.currentStepIdx -= 1;
    this.currentStepId -= 1;

    this.allStepsValid$.next(this.checkAllStepsValid());

    return {
      isFirst: this.currentStepIdx === 0,
      isLast: false,
      command: currStep.command,
    };
  }

  updateStepHint(hint: string): void {
    const updatedStep = {
      ...this.steps[this.currentStepIdx],
      hint,
    };

    const newSteps = [
      ...this.steps.slice(0, this.currentStepIdx),
      updatedStep,
      ...this.steps.slice(this.currentStepIdx + 1),
    ];

    this.setSteps(newSteps);
  }

  updateStepDisabledState(
    stepId: ScheduleStepId,
    disabledState: boolean
  ): void {
    const steps = this.steps;
    const updateStepIndex = steps.findIndex(({ id }) => id === stepId);
    if (updateStepIndex < 0) {
      return;
    }

    steps[updateStepIndex].disabled = disabledState;
    this.setSteps(steps);
  }

  public isStepDisabled(command: string): boolean {
    return this.steps.find((step) => step.command === command)?.disabled;
  }

  public isCurrentStepDiabled(): boolean {
    return this.isStepDisabled(this.getCurrentStep().command);
  }

  public isNextStepDisabled(): boolean {
    return this.isStepDisabled(this.getNextStep().command);
  }

  public getCurrentStep(): ScheduleStep {
    if (this.currentStepId === 0) {
      return this.steps[0];
    }
    for (let i = 0; i < this.steps.length; i++) {
      if (this.steps[i].id === this.currentStepId) {
        this.currentStepIdx = i;
        return this.steps[i];
      }
    }
    return null;
  }

  public getNextStep(): ScheduleStep {
    if (this.currentStepId === 0) {
      return this.steps[1];
    }
    for (let i = 0; i < this.steps.length; i++) {
      if (this.steps[i].id === this.currentStepId) {
        if (i < this.steps.length) {
          return this.steps[i + 1];
        } else {
          return undefined;
        }
      }
    }
    return null;
  }

  getPreviousStep(): ScheduleStep {
    return this.steps.find(({ id }) => id === this.currentStepId);
  }

  public moveToStepById(currentStepId: number, fromMenu?: boolean): void {
    const steps = this.getSteps();

    if (fromMenu) {
      this.fromMenu = fromMenu;
    }
    steps[this.currentStepIdx].active = false;
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].id < currentStepId) {
        steps[i].passed = true;
      }
      if (steps[i].id === currentStepId) {
        this.currentStepId = currentStepId;
        this.currentStepIdx = i;
        steps[i].active = true;
      } else {
        steps[i].active = false;
      }
    }

    this.setSteps(steps);
    this.allStepsValid$.next(this.checkAllStepsValid());
  }

  /** @deprecated */
  public setCurrentStepById(stepId: number): void {
    if (this.fromMenu) {
      return;
    }
    this.fromMenu = false;
    this.currentStepId = stepId;
    let isStepFound = false;
    for (let i = 0; i < this.steps.length; i++) {
      if (!isStepFound) {
        this.steps[i].passed = true;
      }
      if (this.steps[i].id === stepId) {
        isStepFound = true;
        this.steps[i].passed = false;
        this.steps[i].active = true;
        this.currentStepId = stepId;
        this.currentStepIdx = i;
      }
      if (isStepFound && this.steps[i].id !== stepId) {
        this.steps[i].passed = false;
        this.steps[i].active = false;
      }
    }
    this.saveSteps();

    this.allStepsValid$.next(this.checkAllStepsValid());
  }

  /** @deprecated */
  public setCurrentStep(currentStep: ScheduleStep): void {
    this.fromMenu = false;
    if (currentStep.id > this.currentStepId) {
      for (let i = 0; i < this.steps.length; i++) {
        if (this.steps[i].id === currentStep.id) {
          this.currentStepId = currentStep.id;
          this.currentStepIdx = i;
          this.steps[i].active = true;
        } else {
          this.steps[i].active = false;
        }
      }
    }
    this.saveSteps();
    this.allStepsValid$.next(this.checkAllStepsValid());
  }

  public isSelectable(id: number): boolean {
    return this.steps.find((step) => step.id === id)?.selectable;
  }

  public allStepsValid(): Observable<string> {
    return this.allStepsValid$.asObservable();
  }

  public checkAllStepsValid(): string {
    return this.steps.every((step) => step.passed) ? 'valid' : 'not-valid';
  }

  resetSteps(): void {
    this.currentStepId = 1;
    this.currentStepIdx = 0;

    const newSteps = this.steps.map((step) => ({
      ...step,
      passed: false,
      active: false,
    }));

    this.setSteps(newSteps);
  }

  switchScheduleStepper(status: boolean): void {
    this.stepsLoaded$.next(status);
  }

  redirect(path: string, state?: Record<string, any>): Promise<boolean> {
    const relativePath = [...this.router.url.split('/').slice(0, -1), path];
    return this.router.navigate(relativePath, {
      queryParamsHandling: 'preserve',
      state,
    });
  }

  private saveSteps(): void {
    localStorage.setItem('steps', JSON.stringify(this.steps));
  }
}
