import { Injectable, Injector } from '@angular/core';
import {
  ConfigurableScheduleData,
  ScheduleConfiguration,
  ScheduleData,
  ScheduleExtraOptions,
  ScheduleFlow,
  SchedulePayload,
} from '../common/models/schedule-data.model';
import { Patient, PatientStatus } from '../common/models/patient.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActionResponse } from '../common/models/action-response.model';
import * as moment from 'moment';
import { savePhone } from '../common/utils/string-utils.helper';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { IServiceType } from '../data-access/service-type/service-type.model';
import { Practice, PracticeService } from '../common/practice';
import { addPracticeGuid } from '../common/interceptors/practice-guid.interceptor';
import { ScheduleStepsService } from './schedule-steps/schedule-steps.service';
import { IProvider } from '../data-access/provider/provider.model';
import { ServiceTypeService } from '../data-access/service-type/service-type.service';
import { ProviderService } from '../data-access/provider/provider.service';
import { ITimeSlot } from './time-slot/time-slot.model';
import { PatientDetailsService } from './patient-details/patient-details.service';
import { TimeSlotService } from './time-slot/time-slot.service';
import { TranslateService } from '@ngx-translate/core';
import { InsuranceSelectorService } from './patient-details/insurance-selector/insurance-selector.service';
import { InsuranceCompanyItem } from './patient-details/insurance-selector/models';
import { Location } from './location-widget/models/location.interface';
import { GoogleAnalyticsService } from '../common/services/google-analytics.service';
import { ProposalSlotService } from './proposal-slot.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  public scheduleData: ScheduleData;

  readonly scheduleFlow$: BehaviorSubject<ScheduleFlow> =
    new BehaviorSubject<ScheduleFlow>(ScheduleFlow.NEW);

  private readonly scheduleConfiguration$: BehaviorSubject<ScheduleConfiguration> =
    new BehaviorSubject<ScheduleConfiguration>(null);

  constructor(
    readonly data: ScheduleData,
    private readonly http: HttpClient,
    private readonly recaptchaV3Service: ReCaptchaV3Service,
    private readonly practiceService: PracticeService,
    private readonly serviceTypeService: ServiceTypeService,
    private readonly providerService: ProviderService,
    private readonly scheduleStepsService: ScheduleStepsService,
    private readonly patientDetailsService: PatientDetailsService,
    private readonly timeSlotService: TimeSlotService,
    private readonly translateService: TranslateService,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly proposalSlotService: ProposalSlotService,
    private injector: Injector
  ) {
    this.scheduleData = data;
    if (!this.scheduleData.patient) {
      this.scheduleData.patient = new Patient();
    }
  }

  resetScheduleData(): void {
    this.scheduleData = new ScheduleData();
    this.scheduleData.patient = new Patient();

    this.injector
      .get(InsuranceSelectorService)
      .updateInsuranceCompaniesSource(
        (insuranceCompany: InsuranceCompanyItem) => ({
          ...insuranceCompany,
          selected: false,
        })
      );
  }

  getScheduleData(): ScheduleData {
    if (!this.scheduleData) {
      this.scheduleData = new ScheduleData();
      this.scheduleData.patient = new Patient();
    }
    return this.scheduleData;
  }

  postScheduleData(
    selectedSlot: ITimeSlot,
    send_wisetack: boolean = false
  ): Observable<ActionResponse> {
    return this.recaptchaV3Service.execute('postSchedule').pipe(
      take(1),
      map((token) => {
        return new HttpHeaders().set('g-recaptcha-v3', token);
      }),
      withLatestFrom(
        this.practiceService.getPractice(),
        this.getScheduleFlow()
      ),
      switchMap(([httpHeaders, { guid }, scheduleFlow]) => {
        const pid$ =
          scheduleFlow === ScheduleFlow.INVITE
            ? this.patientDetailsService
                .validatePatient(guid, this.scheduleData.patient)
                .pipe(
                  map((response) => response?.pid || ''),
                  catchError(() => of(''))
                )
            : of(
                (this.scheduleData.patient?.belongsToPractices || []).find(
                  ({ practiceGuid }) => practiceGuid === guid
                )?.pid || ''
              );

        return combineLatest([
          of(httpHeaders),
          pid$,
          of(scheduleFlow),
          this.practiceService.getAllPractices(),
        ]);
      }),
      switchMap(([httpHeaders, pid, scheduleFlow, practices]) => {
        const patientEmail = this.resolvePatientDetail('email');
        const patientPhone = this.resolvePatientDetail('mobile_phone');

        const payload: SchedulePayload = {
          provider_id: selectedSlot.provider_id,
          service_id: selectedSlot.service_id,
          time_slot: selectedSlot.date_time,
          patient: {
            pid,
            first_name: this.scheduleData.patient.first_name,
            last_name: this.scheduleData.patient.last_name,
            date_of_birth: moment(
              this.scheduleData.patient.date_of_birth
            ).format('YYYY-MM-DD'),
            email:
              this.scheduleData.patient.patientStatus ===
                PatientStatus.Existing && pid
                ? null
                : patientEmail,
            mobile_phone:
              this.scheduleData.patient.patientStatus ===
                PatientStatus.Existing && pid
                ? null
                : (patientPhone || '').startsWith('+')
                ? patientPhone
                : savePhone(patientPhone, ''),
            guarantor_pid: this.scheduleData.guarantorPid,
            ...this.inuranceCheckFields(scheduleFlow, practices),
          },
          patient_note: this.preparePatientNote(),
          invite_schedule_link_id:
            scheduleFlow === ScheduleFlow.INVITE
              ? this.scheduleConfiguration$.getValue()?.linkId
              : undefined,
          custom_schedule_link_id:
            scheduleFlow === ScheduleFlow.CUSTOM
              ? this.scheduleConfiguration$.getValue()?.linkId
              : undefined,
          from_widget: this.scheduleConfiguration$.getValue()?.from_widget,
          mode: this.scheduleConfiguration$.getValue()?.mode,
          practice_insurance_company_id: !!this.scheduleData.patient.insurance
            ?.insuranceCompanies?.length
            ? this.scheduleData.patient.insurance.insuranceCompanies[0].id
            : null,
          other_insurance_company: this.scheduleData.patient.insurance?.other
            ? this.scheduleData.patient.insurance.otherNote || 'Other'
            : this.scheduleData.patient.isInsuranceChanged ||
              this.scheduleData.patient.patientStatus ===
                PatientStatus.NewPatient
            ? this.scheduleData.patient.insurance?.payMyself
              ? 'I’ll pay by myself'
              : null
            : 'didn’t change',
          send_wisetack,
        };
        return this.http
          .post<ActionResponse>('book/schedule', payload, {
            headers: httpHeaders,
            context: addPracticeGuid(),
          })
          .pipe(
            tap(() =>
              this.googleAnalyticsService.trackEvent(
                'Scheduled Appointment',
                payload
              )
            )
          );
      })
    );
  }

  loadScheduleOptions(
    flow: ScheduleFlow,
    configurableData: ConfigurableScheduleData,
    options?: Partial<ScheduleExtraOptions>
  ): void {
    const { stepperEnabled = true } = options ?? {};

    this.setScheduleFlow(flow);

    const {
      practiceGroupName,
      mainPractice,
      practices,
      serviceTypes,
      providers,
      patient,
      scheduleTimeFrame,
      proposedTimeSlot,
      configuration,
      proposal,
    } = configurableData;
    this.practiceService.setShouldShowGroupName(practices.length > 1);

    switch (flow) {
      case ScheduleFlow.NEW: {
        this.practiceService.setPractice(mainPractice);
        this.practiceService.setPractices(practices);
        break;
      }
      case ScheduleFlow.CUSTOM: {
        this.practiceService.setPractice(mainPractice);
        this.practiceService.setPracticeGroupName(practiceGroupName);
        this.practiceService.setPractices(practices);
        this.serviceTypeService.setServiceTypes(serviceTypes);
        this.providerService.setProviders(providers);
        break;
      }
      case ScheduleFlow.INVITE: {
        this.practiceService.setPractice(mainPractice);
        this.practiceService.setPractices(practices);
        this.serviceTypeService.setServiceTypes(serviceTypes);
        this.serviceTypeService.setSelectedServiceType(serviceTypes[0]);
        this.providerService.setProviders(providers);
        this.patientDetailsService.setPatientDetails(patient);
        this.scheduleData.patient = patient;
        this.proposalSlotService.setProposalSlot(proposal);
        this.timeSlotService.setScheduleTimeFrame(scheduleTimeFrame);

        if (proposedTimeSlot) {
          this.scheduleData.providers = [providers[0]];
          this.scheduleData.patient.selectedServiceData = serviceTypes[0];
          this.scheduleData.patient.selectedLocationsData = [mainPractice];
          this.scheduleData.patient.selectedTimeSlotsData = [
            {
              date_time: proposedTimeSlot,
              practice_guid: mainPractice.guid,
              provider_id: providers[0].id,
              service_id: serviceTypes[0].id,
            },
          ];
          this.timeSlotService.setProposedTimeSlot(proposedTimeSlot);
        }
        break;
      }
    }

    this.setScheduleConfiguration(configuration);
    this.scheduleStepsService.switchScheduleStepper(stepperEnabled);
  }

  setScheduleFlow(flow: ScheduleFlow): void {
    this.scheduleFlow$.next(flow);
  }

  getScheduleFlow(): Observable<ScheduleFlow> {
    return this.scheduleFlow$.asObservable();
  }

  setScheduleConfiguration(config: ScheduleConfiguration): void {
    this.scheduleConfiguration$.next(config);
  }

  getScheduleConfiguration(): Observable<ScheduleConfiguration> {
    return this.scheduleConfiguration$.asObservable();
  }

  createScheduleWithPatient(): void {
    const patientDetails = this.scheduleData.patient;
    this.scheduleData = new ScheduleData();
    this.scheduleData.patient = {
      ...new Patient(),
      first_name: patientDetails.first_name,
      last_name: patientDetails.last_name,
      email: patientDetails.email,
      date_of_birth: patientDetails.date_of_birth,
      mobile_phone: patientDetails.mobile_phone,
      belongsToPractices: patientDetails.belongsToPractices,
      patientStatus: PatientStatus.Existing,
      insurance: patientDetails.insurance,
      patientType: patientDetails.patientType,
    };
  }

  setInformationForDoctor(text: string): void {
    this.scheduleData.informationForDoctor = text;
  }

  resetPatientTimeSlots(): void {
    this.scheduleData.patient.selectedTimeSlots = [];
    this.scheduleData.patient.selectedTimeSlotsData = [];
  }

  resetPatientProviders(): void {
    this.scheduleData.patient.selectedProviders = [];
    this.scheduleData.patient.selectedProvidersData = [];
  }

  setPatientServiceType(id: number, data: IServiceType): void {
    this.scheduleData.patient.selectedService = id;
    this.scheduleData.patient.selectedServiceData = data;
  }

  setPatientProviders(id: number, data: IProvider): void {
    this.scheduleData.patient.selectedProviders = [id];
    this.scheduleData.patient.selectedProvidersData = [data];
  }

  setLocation(id: number, data: Practice): void {
    this.scheduleData.patient.selectedLocations = [id];
    this.scheduleData.patient.selectedLocationsData = [data];
  }

  createNewBooking(restoreDetails: boolean): Promise<void> {
    this.scheduleStepsService.resetSteps();
    if (restoreDetails) {
      this.createScheduleWithPatient();
      this.patientDetailsService.setPatientDetails({
        ...this.patientDetailsService.patientDetails,
        patientStatus: PatientStatus.Existing,
      });
    } else {
      this.resetScheduleData();
      this.patientDetailsService.setPatientDetails(null);

      if (
        this.scheduleFlow$.getValue() === ScheduleFlow.CUSTOM &&
        this.practiceService.practices.length > 1
      ) {
        this.practiceService.setPractice(null);
      }
    }

    this.serviceTypeService.setSelectedServiceType(null);
    this.timeSlotService.setTimeSlots(null);
    this.timeSlotService.resetSwiperState();

    const startingStep =
      this.scheduleStepsService.steps.filter(({ disabled }) => !disabled)[
        restoreDetails ? 1 : 0
      ] || this.scheduleStepsService.firstAvailableStep;

    this.scheduleStepsService.moveToStepById(startingStep.id);

    const validate$ = restoreDetails
      ? this.patientDetailsService
          .validatePatient(
            this.practiceService.practice.guid,
            this.scheduleData.patient
          )
          .pipe(
            map(({ pid, email, mobile_phone, updated_at }) => ({
              pid,
              patientDetails: { email, mobile_phone, updated_at },
            })),
            catchError(() => of({ pid: '', patientDetails: null })),
            tap(({ pid, patientDetails }) => {
              const practiceIdx =
                this.scheduleData.patient.belongsToPractices.findIndex(
                  ({ practiceGuid }) =>
                    practiceGuid === this.practiceService.practice.guid
                );

              if (practiceIdx < 0) {
                this.scheduleData.patient.belongsToPractices = [
                  ...this.scheduleData.patient.belongsToPractices,
                  {
                    practiceGuid: this.practiceService.practice.guid,
                    pid,
                    patientDetails,
                  },
                ];
                return;
              }

              this.scheduleData.patient.belongsToPractices = [
                ...this.scheduleData.patient.belongsToPractices.slice(
                  0,
                  practiceIdx
                ),
                {
                  ...this.scheduleData.patient.belongsToPractices[practiceIdx],
                  pid,
                },
                ...this.scheduleData.patient.belongsToPractices.slice(
                  practiceIdx + 1
                ),
              ];
            })
          )
      : EMPTY;

    return validate$
      .toPromise()
      .then(() => this.scheduleStepsService.redirect(startingStep.command))
      .then(() => this.scheduleStepsService.stepsLoaded$.next(true));
  }

  isPatientNew(): boolean {
    return this.scheduleData.newPatient;
  }

  private resolvePatientDetail(key: keyof Patient): string {
    return (
      this.scheduleData.patient[key] ||
      (this.scheduleData.patient?.belongsToPractices || [])
        .filter(({ patientDetails }) => !!patientDetails?.[key])
        .sort((a, b) =>
          a.patientDetails.updated_at.localeCompare(b.patientDetails.updated_at)
        )
        .map(({ patientDetails }) => patientDetails[key])
        .pop() ||
      null
    );
  }

  private preparePatientNote(): string {
    let insuranceNote = '';
    const { insurance, isInsuranceChanged } = this.scheduleData.patient;
    if (insurance && [undefined, true].includes(isInsuranceChanged)) {
      insuranceNote = `${this.translateService.instant(
        'PATIENT_DETAILS.FORM.INSURANCE.LABEL'
      )}: `;
      if (insurance.payMyself) {
        insuranceNote += `${this.translateService.instant(
          'PATIENT_DETAILS.FORM.INSURANCE.PAY_MYSELF_BTN'
        )}`;
      } else if (insurance.other) {
        const selectedInsuranceCompaniesNames =
          this.scheduleData.patient.insurance.insuranceCompanies
            .map(({ name }) => name)
            .join(', ');
        insuranceNote += `${
          selectedInsuranceCompaniesNames
            ? selectedInsuranceCompaniesNames + ','
            : ''
        } ${this.translateService.instant(
          'PATIENT_DETAILS.FORM.INSURANCE.OTHER_BTN'
        )} ${this.scheduleData.patient.insurance.otherNote}`;
      } else if (insurance.insuranceCompanies.length) {
        const selectedInsuranceCompaniesNames =
          this.scheduleData.patient.insurance.insuranceCompanies
            .map(({ name }) => name)
            .join(', ');
        insuranceNote += selectedInsuranceCompaniesNames;
      }
    }

    return `${this.scheduleData.informationForDoctor || ''} ${insuranceNote}`;
  }

  private inuranceCheckFields(
    scheduleFlow: ScheduleFlow,
    practices: Location[]
  ): {} {
    if (scheduleFlow === ScheduleFlow.NEW) {
      return practices[0].dashboard_insurance_check
        ? {
            member_id: this.scheduleData.patient.insurance?.memberId || null,
            group_number:
              this.scheduleData.patient.insurance?.groupNumber || null,
          }
        : {};
    } else if (scheduleFlow === ScheduleFlow.CUSTOM) {
      return this.scheduleConfiguration$.getValue()?.requestInsuranceInfo
        ? {
            member_id: this.scheduleData.patient.insurance?.memberId || null,
            group_number:
              this.scheduleData.patient.insurance?.groupNumber || null,
          }
        : {};
    } else {
      return {};
    }
  }
}
