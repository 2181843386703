import { Family, Patient, PatientPayload } from './patient.model';
import { IProvider } from '../../data-access/provider/provider.model';
import { Practice } from '../practice';
import { ITimeSlot } from '../../main/time-slot/time-slot.model';
import { Injectable } from '@angular/core';
import { IServiceType } from '../../data-access/service-type/service-type.model';
import { InvitePatientLinkProposal } from '../../data-access/invite-patient/invite-patient.model';

@Injectable()
export class ScheduleData {
  newPatient = false;
  withFamily = false;
  forMyself = true;
  patient: Patient;
  role?: FamilyRole;
  guardian: Patient;
  family: FamilyData[] = [];
  providers: IProvider[] = [];
  locations: Practice[] = [];
  timeSlots: ITimeSlot[] = [];
  informationForDoctor: string;
  hasFamilyMember = false;
  familyMember: Family = null;
  guarantorPid: string = null;
  proposal?: InvitePatientLinkProposal;
}

export class FamilyData {
  patient: Patient;
  role: FamilyRole;
  valid = false;
  selected = false;
}

export class FamilyRole {
  id: number;
  label: string;
}

export enum ScheduleFlow {
  NEW = 'new',
  CUSTOM = 'custom',
  INVITE = 'invite',
}

export interface ConfigurableScheduleOptions {
  practices: Practice[];
  serviceTypes: IServiceType[];
  providers: IProvider[];
  proposal?: InvitePatientLinkProposal;
}

export interface ScheduleExtraOptions {
  stepperEnabled: boolean;
}

export interface ConfigurableScheduleData extends ConfigurableScheduleOptions {
  flow: ScheduleFlow;
  practiceGroupName: string;
  mainPractice: Practice;
  patient: Patient;
  perfectSlot: string;
  proposedTimeSlot: string;
  scheduleTimeFrame: string;
  configuration: ScheduleConfiguration;
}

export interface SchedulePayload {
  provider_id: number;
  service_id: number;
  time_slot: string;
  patient: PatientPayload;
  patient_note: string;
  invite_schedule_link_id?: number;
  custom_schedule_link_id?: number;
  practice_insurance_company_id: number;
  other_insurance_company: string;
  send_wisetack: boolean;
  from_widget?: string;
  mode?: string;
}

export interface ScheduleConfiguration {
  linkId: number;
  from_widget?: string;
  mode?: string;
  requestInsuranceInfo?: boolean;
}
