import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorRedirectReason } from '../common/models/error-redirect-reason.model';
import {
  ConfigurableScheduleData,
  ScheduleFlow,
} from '../common/models/schedule-data.model';
import { InvitePatientLink } from '../data-access/invite-patient/invite-patient.model';
import { InvitePatientService } from '../data-access/invite-patient/invite-patient.service';
import { ScheduleStepsService } from '../main/schedule-steps/schedule-steps.service';
import { ScheduleService } from '../main/schedule.service';
import { HttpErrorResponse } from '@angular/common/http';
import { InvitePrepareOnSuccess } from './models';
import { GoogleAnalyticsService } from '../common/services/google-analytics.service';
import { ScheduleStepId } from '../main/schedule-steps/schedule-step.model';

@Injectable({
  providedIn: 'root',
})
export class InvitePatientGuard implements CanActivate {
  NOT_FOUND_URL = 'page-not-found';
  constructor(
    private readonly router: Router,
    private readonly scheduleService: ScheduleService,
    private readonly scheduleStepsService: ScheduleStepsService,
    private readonly invitePatientService: InvitePatientService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const inviteLinkId: string = route.params.inviteLinkId;

    const { from_practice_id, signature, expires } = route.queryParams as {
      from_practice_id: string;
      signature: string;
      expires: string;
    };

    if (!(inviteLinkId && from_practice_id && signature && expires)) {
      this.redirectWithIncorrectLink();
      return of(false);
    }

    return this.invitePatientService
      .decodeInviteScheduleLink(
        inviteLinkId,
        from_practice_id,
        signature,
        expires
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.redirectBasedOnErrorCode(err);
        }),
        map((decodedData: InvitePatientLink | null) => {
          if (
            !decodedData?.service_types?.length &&
            !decodedData?.group_service_types?.length
          ) {
            this.redirectWithIncorrectLink();
            return false;
          }

          return this.prepareOnSuccess({
            decodedData,
            from_practice_id,
            signature,
            expires,
          });
        })
      );
  }

  private redirectWithIncorrectLink(): void {
    this.router.navigate([this.NOT_FOUND_URL], {
      state: { reason: ErrorRedirectReason.INCORRECT_LINK },
    });
  }

  private redirectBasedOnErrorCode({
    status,
    error: { message },
  }: HttpErrorResponse): Observable<false> {
    switch (status) {
      case 403:
        this.router.navigate([this.NOT_FOUND_URL], {
          state: { reason: ErrorRedirectReason.LINK_EXPIRED, title: message },
        });
        return of(false);
      case 422:
        this.router.navigate([this.NOT_FOUND_URL], {
          state: {
            reason: ErrorRedirectReason.LINK_ALREADY_USED,
            title: message,
          },
        });
        return of(false);
      default:
        this.router.navigate([this.NOT_FOUND_URL], {
          state: { reason: ErrorRedirectReason.INCORRECT_LINK, title: message },
        });
        return of(false);
    }
  }

  private prepareOnSuccess({
    decodedData,
    from_practice_id,
    signature,
    expires,
  }: InvitePrepareOnSuccess): true {
    const preparedData = this.invitePatientService.mapInviteScheduleData(
      decodedData,
      {
        from_practice_id: +from_practice_id,
        signature,
        expires: +expires,
      }
    );

    this.setInviteSteps(
      preparedData,
      decodedData.proposal ? ScheduleStepId.SUMMARY : ScheduleStepId.TIME_SLOT
    );
    this.scheduleStepsService.switchScheduleStepper(!!decodedData.proposal);

    this.scheduleService.loadScheduleOptions(
      ScheduleFlow.INVITE,
      preparedData,
      { stepperEnabled: !decodedData.proposal }
    );

    if (decodedData?.os_gtm_tag) {
      this.googleAnalyticsService.initializeGtm(decodedData.os_gtm_tag);
    }

    return true;
  }

  private setInviteSteps(
    preparedData: ConfigurableScheduleData,
    redirectStep: ScheduleStepId.TIME_SLOT | ScheduleStepId.SUMMARY
  ): void {
    const steps = this.scheduleStepsService.buildScheduleSteps([
      {
        slug: 'patient-details',
        disabled: true,
        hint: `${preparedData.patient.first_name} ${preparedData.patient.last_name}`,
      },
      {
        slug: 'reason-for-visit',
        disabled: true,
        hint: `${preparedData.serviceTypes[0].name}`,
      },
      {
        slug: 'time-slot',
        disabled: redirectStep === 4,
      },
      { slug: 'summary', disabled: redirectStep === 3 },
    ]);

    this.scheduleStepsService.setSteps(steps);
    this.scheduleStepsService.moveToStepById(redirectStep);
  }
}
