export interface AvailableScheduleStep {
  slug: ScheduleStepName;
  label: string;
}

export interface ConfigurableScheduleStep {
  slug: string;
  disabled?: boolean;
  hint?: string;
  passed?: boolean;
}

export interface ScheduleStep {
  id: number;
  label: string;
  command: ScheduleStepName;
  active: boolean;
  passed: boolean;
  selectable: boolean;
  disabled: boolean;
  hint: string;
}

export interface StepState {
  isFirst: boolean;
  isLast: boolean;
  command: string;
}

export enum ScheduleStepName {
  PATIENT_DETAILS = 'patient-details',
  REASON_FOR_VISIT = 'reason-for-visit',
  TIME_SLOT = 'time-slot',
  SUMMARY = 'summary',
  FAMILY = 'family',
}

export enum ScheduleStepId {
  PATIENT_DETAILS = 1,
  REASON_FOR_VISIT = 2,
  TIME_SLOT = 3,
  SUMMARY = 4,
}
