import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DeviceService } from '../common/services/device.service';
import { ErrorRedirectReason } from '../common/models/error-redirect-reason.model';
import {
  ConfigurableScheduleData,
  ScheduleFlow,
} from '../common/models/schedule-data.model';
import { PracticeService } from '../common/practice';
import { ScheduleStepsService } from '../main/schedule-steps/schedule-steps.service';
import { ScheduleService } from '../main/schedule.service';
import { GoogleAnalyticsService } from '../common/services/google-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class PracticeGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly practiceService: PracticeService,
    private readonly scheduleService: ScheduleService,
    private readonly scheduleStepsService: ScheduleStepsService,
    private readonly deviceService: DeviceService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const practiceGuid: string = route.params.practiceGuid;

    if (!practiceGuid) {
      this.router.navigate(['page-not-found'], {
        state: { reason: ErrorRedirectReason.PRACTICE_NOT_FOUND },
      });
      return of(false);
    }

    return this.practiceService.getPracticeData(practiceGuid).pipe(
      catchError(() => of(null)),
      map((practice) => {
        if (practice) {
          this.deviceService.setMetadata(practice);

          const steps = this.scheduleStepsService.buildScheduleSteps([
            { slug: 'patient-details' },
            { slug: 'reason-for-visit' },
            { slug: 'time-slot' },
            { slug: 'summary' },
          ]);
          this.scheduleStepsService.setSteps(steps);
          this.scheduleStepsService.moveToStepById(1);

          this.scheduleService.loadScheduleOptions(ScheduleFlow.NEW, {
            flow: ScheduleFlow.NEW,
            mainPractice: practice,
            practices: [practice],
          } as ConfigurableScheduleData);
          sessionStorage.setItem('lastPractice', practiceGuid);
          if (practice.os_gtm_tag) {
            this.googleAnalyticsService.initializeGtm(practice.os_gtm_tag);
          }
          return true;
        }

        this.router.navigate(['page-not-found'], {
          state: { reason: ErrorRedirectReason.PRACTICE_NOT_FOUND },
        });
        return false;
      })
    );
  }
}
