import { inject, Injectable } from '@angular/core';
import { TimeOfDayFilter } from '../../main/time-slot/time-slot.model';
import { GoogleAnalyticsService } from './google-analytics.service';
import { BeginScheduleTrackingPayload } from './google-analytics-events.types';

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsEventsService {
  private readonly googleAnalyticsService = inject(GoogleAnalyticsService);

  trackPickTimeSlotDay(day: string): void {
    this.googleAnalyticsService.trackEvent('Time slot - pick day', {
      day,
    });
  }

  trackSelectTimeOfDayEvent(timeOfDay: TimeOfDayFilter): void {
    this.googleAnalyticsService.trackEvent('Select time of day', {
      time_of_day: timeOfDay,
    });
  }

  trackUnselectTimeOfDayEvent(timeOfDay: TimeOfDayFilter): void {
    this.googleAnalyticsService.trackEvent('Unselect time of day', {
      time_of_day: timeOfDay,
    });
  }

  trackGoBackEvent(
    back_to: 'reason_for_visit' | 'time_slot' | 'patient_details'
  ): void {
    this.googleAnalyticsService.trackEvent('Click back button', {
      back_to,
    });
  }

  trackChangeWeekEvent({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }): void {
    this.googleAnalyticsService.trackEvent('Pick week', {
      week: `${startDate} - ${endDate}`,
    });
  }

  trackSelectedTimeSlotEvent(datetime: string, provider: string): void {
    this.googleAnalyticsService.trackEvent('Pick slot', {
      datetime,
      provider,
    });
  }

  trackLocationSelected(name: string): void {
    this.googleAnalyticsService.trackEvent('Pick location', {
      name,
    });
  }

  trackBeginSchedule(trackingPayload: BeginScheduleTrackingPayload): void {
    this.googleAnalyticsService.trackEvent('Begin schedule', trackingPayload);
  }

  trackOpenedView(
    view: 'time_slot' | 'summary_page' | 'reason_for_visit' | 'patient_details'
  ): void {
    this.googleAnalyticsService.trackEvent('Open view', {
      view,
    });
  }

  trackPickedReasonToVisit(name: string): void {
    this.googleAnalyticsService.trackEvent('Pick reason for visit', {
      name,
    });
  }

  trackViewedEngagementFirstStep(payload: {
    practiceId: number;
    practiceGuid: string;
    practiceName: string;
  }): void {
    this.googleAnalyticsService.trackEvent(
      'Viewed Engagement first step',
      payload
    );
  }

  trackExistingPatientPick(): void {
    this.googleAnalyticsService.trackEvent('Existing patient picked');
  }

  trackNewPatientPick(): void {
    this.googleAnalyticsService.trackEvent('New patient picked');
  }
}
