import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../providers/window';
import { DOCUMENT } from '@angular/common';
import { Gtag } from '../models/google-analytics.model';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(
    @Inject(WINDOW) public readonly windowRef: Window,
    @Inject(DOCUMENT) private readonly documentRef: Document
  ) {}

  initializeGtm(id: string): void {
    this.loadGtm(id);
  }

  initializeGtag(id: string): void {
    this.loadGtag(id);
  }

  trackEvent(event: string, properties: unknown = {}): void {
    const gtag = (this.windowRef as Window & { gtag: Gtag }).gtag;

    if (!gtag) {
      return;
    }

    gtag('event', event, properties);
  }

  private loadGtag(id: string): void {
    const loadScript = this.documentRef.createElement('script');
    loadScript.setAttribute('async', 'true');
    loadScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${id}`
    );

    const initScript = this.documentRef.createElement('script');
    initScript.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${id}');`;

    this.documentRef.documentElement.firstChild.appendChild(loadScript);
    this.documentRef.documentElement.firstChild.appendChild(initScript);
  }

  private loadGtm(id: string): void {
    const headScript = this.documentRef.createElement('script');
    headScript.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${id}');`;

    const bodyScript = this.documentRef.createElement('noscript');
    const bodyScriptContent = this.documentRef.createElement('iframe');
    bodyScriptContent.setAttribute(
      'src',
      `https://www.googletagmanager.com/ns.html?id=${id}`
    );
    bodyScriptContent.setAttribute('height', '0');
    bodyScriptContent.setAttribute('width', '0');
    bodyScriptContent.setAttribute('style', 'display:none;visibility:hidden');

    bodyScript.appendChild(bodyScriptContent);

    this.documentRef.documentElement.firstChild.appendChild(headScript);
    this.documentRef.body.prepend(bodyScript);
  }
}
