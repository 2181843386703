import { Injectable } from '@angular/core';
import { InvitePatientLinkProposal } from '../data-access/invite-patient/invite-patient.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProposalSlotService {
  private readonly proposalSlot$: BehaviorSubject<InvitePatientLinkProposal> =
    new BehaviorSubject(null);

  getProposalSlot(): InvitePatientLinkProposal {
    return this.proposalSlot$.value;
  }

  getProposalSlot$(): Observable<InvitePatientLinkProposal> {
    return this.proposalSlot$.asObservable();
  }

  setProposalSlot(newValue: InvitePatientLinkProposal): void {
    this.proposalSlot$.next(newValue);
  }

  updateProposalSlot(updatedValue: Partial<InvitePatientLinkProposal>): void {
    if (!this.getProposalSlot()) {
      return;
    }

    this.proposalSlot$.next({ ...this.getProposalSlot(), ...updatedValue });
  }
}
