import {
  IProvider,
  ProviderType,
} from '../../data-access/provider/provider.model';
import { IServiceType } from '../../data-access/service-type/service-type.model';
import { ITimeSlot } from '../../main/time-slot/time-slot.model';
import { Practice } from '../practice';
import { InsuranceSettings } from '../../main/patient-details/insurance-selector/models';

export enum PatientType {
  Adult,
  Minor,
  Both,
}

export enum PatientStatus {
  Existing,
  NewPatient,
  Both,
}

export class Patient {
  id: number;
  pid: string;
  first_name: string;
  last_name: string;
  mobile_phone: string;
  email: string;
  date_of_birth?: Date;
  isInsuranceChanged?: boolean;
  insurance: InsuranceSettings;
  belongsToPractices?: LinkedPatient[] = [];
  guardian?: Patient;
  locations?: Array<string> = [];
  patientType: PatientType = PatientType.Adult;
  patientStatus: PatientStatus;
  selectedService: number;
  selectedServiceData: IServiceType;
  selectedProviders: Array<number> = [];
  selectedProvidersData: IProvider[] = [];
  selectedProviderType: ProviderType = ProviderType.ANY;
  selectedLocations: Array<number> = [];
  selectedLocationsData: Array<Practice> = [];
  timeSlots: Array<ITimeSlot> = [];
  selectedTimeSlots: Array<number> = [];
  selectedTimeSlotsData: Array<ITimeSlot> = [];
  bookedTimeSlots: Array<number> = [];
  bookedTimeSlotsData: Array<ITimeSlot> = [];
  is_new: boolean;
  has_family: boolean;
  family?: Family;
}

export interface PatientPayload {
  pid: string;
  first_name: string;
  last_name: string;
  mobile_phone: string;
  email: string;
  date_of_birth?: string;
  member_id?: string;
  group_number?: string;
  guarantor_pid?: string;
}

export interface LinkedPatient {
  pid: string;
  practiceGuid: string;
  patientDetails: {
    email: string;
    mobile_phone: string;
    updated_at: string;
  };
}

export interface Family {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  mobile_phone: string;
  email: string;
}

export interface PatientData {
  id: number;
  pid: string;
  first_name: string;
  last_name: string;
  preferred_name: string;
  mobile_phone: string;
  date_of_birth: string;
  email: string;
  image: string | null;
  color: string;
  patient_context: string | null;
  bo_inactive: boolean;
  is_new: boolean;
  non_patient: boolean;
  prospective: boolean;
  practice_id: number;
  sex: string;
  has_insurance: boolean;
  first_visit_date: string;
  last_visit_date: string;
  email_optout: number;
  phone_optout: number;
  phone_reminders_optout: number;
  review_optout: number;
  phone_undeliverable: boolean;
  email_undeliverable: boolean;
  balance_0_30: number;
  balance_31_60: number;
  balance_61_90: number;
  balance_91_plus: number;
  balance_insurance_est: number;
  balance_patient_est: number;
  balance_total: number;
  open_claims: number;
  guardian_id: number;
  guarantor_context: string;
  user_active: boolean;
  user_push_active: boolean;
  user_id: number;
  user_email: string;
  guardian_user_id: number;
  created_at: string;
  updated_at: string;
  hygiene_recall_due: string;
  medical_updated_at: string;
  force_independent: boolean;
  can_receive_push: boolean;
  can_receive_sms: boolean;
  can_receive_email: boolean;
}
